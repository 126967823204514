import useCountryBasedData from '@Hooks/useCountryBasedData';
import useMedias from '@Hooks/useMedias';
import images from '@Promo/other-banners/promo-sozdaem-myagkost-bytiya/assets';

import type { InlineBannerData } from '@Promo/typings';

export interface Params {
  slug: string;
}

const useMiniBanners = (params: Params): InlineBannerData[] => {
  const { slug } = params;
  const { isMobileXXS } = useMedias();

  const bannerRight = {
    id: 1,
    header: 'Авторские принты',
    text: !isMobileXXS
      ? 'Созданы художниками фонда «Антон тут рядом»'
      : 'Созданы художниками фонда<br> «Антон тут рядом»',
    image: {
      desktop: { src: images.desktopRight },
      mobile: { src: images.mobileRight },
    },
    inversed: true,
    template: 'mini',
  };

  const bannerLeft = {
    id: 2,
    header: 'Забота во всем',
    text: !isMobileXXS
      ? 'Тактильно приятные ткани из переработанных материалов'
      : 'Тактильно приятные ткани<br> из переработанных материалов',
    image: {
      desktop: { src: images.desktopLeft },
      mobile: { src: images.mobileLeft },
    },
    inversed: true,
    template: 'mini',
  };

  const banners = useCountryBasedData({
    RUS: {
      'promo-anton-tut-ryadom': [bannerRight, bannerLeft],
    },
    BLR: {},
    KAZ: {},
    default: {},
  });

  return banners[slug];
};

export default useMiniBanners;

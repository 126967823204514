import { memo, useEffect, useState, useCallback, useMemo } from 'react';
import { IconChevronBottom } from '@divlab/divanui/icons';
import { cn, RawHtml } from '@divlab/divanui';

import useTranslation from '@Queries/useTranslation';
import styles from './FooterText.module.css';

import type { FC, HTMLAttributes } from 'react';

interface FooterTextProps extends HTMLAttributes<HTMLDivElement> {
  texts: string[];
  className?: string;
}

const FooterText: FC<FooterTextProps> = (props) => {
  const { className, texts, ...restProps } = props;
  const [loaded, setLoaded] = useState(false);
  const [collapsed, setCollapsed] = useState(true);
  const { t } = useTranslation();
  const paragraphs = [...texts];
  const [firstParagraph] = paragraphs.splice(0, 1);
  const [lastParagraph] = paragraphs.splice(-1);
  const isReadMoreButtonVisible = lastParagraph && collapsed;

  useEffect(() => {
    setLoaded(true);
  }, []);

  const handleButton = useCallback(() => {
    setCollapsed((prevState) => !prevState);
  }, []);

  const buttonReadMore = useMemo(() => {
    if (!loaded) return null;

    return (
      <div className={styles.button} onClick={handleButton}>
        {' '}
        {collapsed ? t('ui.read-more') : t('ui.hide')}
        <IconChevronBottom className={cn(styles.chevron, { [styles.active]: !collapsed })} />
      </div>
    );
  }, [t, handleButton, collapsed, loaded]);

  return (
    <div {...restProps} className={className}>
      <div className={styles.block}>
        <RawHtml className={styles.inlineParagraph} key={firstParagraph} html={firstParagraph} />
        {isReadMoreButtonVisible && buttonReadMore}
      </div>

      {!!lastParagraph && (
        <div className={cn(styles.block, { [styles.hidden]: loaded && collapsed })}>
          {paragraphs.map((item) => {
            return <RawHtml key={item} html={item} />;
          })}

          <RawHtml className={styles.inlineParagraph} key={lastParagraph} html={lastParagraph} />
          {buttonReadMore}
        </div>
      )}
    </div>
  );
};

export default memo(FooterText);

import { update } from '@kundinos/nanostores';

import { popupStore } from './store';

import type { PopupData } from '@Promo/typings';

function add(popup: PopupData) {
  update(popupStore, (value) => {
    const newItems = value.items;
    if (newItems.find((item) => item.id === popup.id)) {
      return value;
    }
    newItems.push({
      id: popup.id,
      data: popup,
      visible: false,
      closed: false,
    });
    return { ...value, items: newItems };
  });
}

export default add;

import { memo, useCallback } from 'react';
import { SampleOption, SizesSelect as UISizesSelect } from '@divlab/divanui';

import Link from '@Navigation/Link';
import styles from './SizesSelect.module.css';

import type { SizesSelectProps as UISizesSelectProps, SelectItemData } from '@divlab/divanui';
import type { FC, MouseEvent } from 'react';

export interface SizesSelectProps extends UISizesSelectProps {
  onClickItem?: (e: MouseEvent, item: SelectItemData) => void;
}

const SizesSelect: FC<SizesSelectProps> = (props) => {
  const { items, onClickItem, ...restProps } = props;

  const handleClickItem = useCallback(
    (e: MouseEvent, item: SelectItemData) => {
      if (onClickItem) onClickItem(e, item);
    },
    [onClickItem],
  );

  return (
    <UISizesSelect
      {...restProps}
      defaultChecked={items.find((option: SelectItemData) => option.selected)}
      items={items}
      disabled={items.length < 2}
      renderItem={(itemProps: SelectItemData) => {
        return (
          <Link className={styles.option} to={itemProps.href}>
            <SampleOption
              item={itemProps}
              onClick={(e: MouseEvent) => handleClickItem(e, itemProps)}
            />
          </Link>
        );
      }}
    />
  );
};

export default memo(SizesSelect);

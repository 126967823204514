import { memo, useCallback, useMemo } from 'react';
import { Button, Discount, Price, cn } from '@divlab/divanui';
import { IconBasket, IconCheckBasket } from '@divlab/divanui/icons';

import * as ModalWindows from '@Stores/ModalWindows';
import { useRequest } from '@Contexts/Request';
import useRenderType from '@Hooks/useRenderType';
import useMedias from '@Hooks/useMedias';
import { useMeta } from '@Queries/Meta';
import { useHasInCart } from '@Queries/Cart';
import useNavigation from '@Navigation/useNavigation';
import ProductTags from '@Components/ProductTags';
import ProductLike from '@Components/ProductLike';
import Characteristics from '@Components/Characteristics';
import BuyButton from '@Components/BuyButton';
import OnSaleSoonTab from '@Components/OnSaleSoonTab';
import InfoTab from '@Components/InfoTab';
import AboutReceiptButton from '@Components/AboutReceiptButton';
import Link from '@Navigation/Link';
import getProductName from '@Utils/getProductName';
import LinearTag from '@Promo/elems/LinearTag';
import ProductSelect from './elements/ProductSelect';
import SEOMetaData from './elements/SEOMetaData';
import ProductImage from '@Components/ProductImage';
import CreditTabWrapper from '@Components/CreditTabWrapper';
import styles from './CrossSaleProductCard.module.css';

import type { ProductData, ProductGroups } from '@Types/Product';
import type { HTMLAttributes, FC, MouseEvent } from 'react';

export interface CrossSaleProductCardProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  product: ProductData;
  hiddenCharacteristics?: boolean;
  hiddenBuyLink?: boolean;
  isExtraSale?: boolean;
  imageLazyLoading?: boolean;
  onClickProductLink?: (e: MouseEvent) => void;
  needBuyButton?: boolean;
  analyticsLabel?: string;
  needComingSoonInfo?: boolean;
  hidenCreditTab?: boolean;
}

const CrossSaleProductCard: FC<CrossSaleProductCardProps> = (props) => {
  const {
    className,
    product,
    hiddenCharacteristics,
    hiddenBuyLink,
    isExtraSale,
    imageLazyLoading,
    onClickProductLink,
    needBuyButton,
    itemScope,
    analyticsLabel,
    needComingSoonInfo,
    hidenCreditTab,
    ...restProps
  } = props;
  const { isCSR, isSSR } = useRenderType();
  const { isMobileM } = useMedias();
  const { country } = useRequest();
  const hasExpired = product.price.expired > 0;
  const hasDiscount = product.price.discount > 0;
  const [firstImage] = product.images;
  const orientation = product.orientation || 'landscape';
  const isUZB = country === 'UZB';
  const { openPage } = useNavigation();
  const meta = useMeta();
  const { region } = meta.data;
  const shopProduct = useMemo(
    () => ({
      isModular: false,
      shopProductId: product.id,
    }),
    [product.id],
  );
  const hasInCart = useHasInCart(shopProduct);
  const isNotAvailable = isCSR && !product.isActive;
  const isComingSoon = product.status === 'ComingSoon';
  const isShowInfoTab = (isNotAvailable && !isComingSoon) || (needComingSoonInfo && isComingSoon);

  const mainCredit = useMemo(() => {
    if (!product.credit) return null;
    if (!Array.isArray(product.credit)) return product.credit;

    const availableCredit = product.credit.find((credit) => credit.isMain);

    if (availableCredit) {
      return availableCredit;
    }
  }, [product.credit]);

  const isYandexSplit = mainCredit?.type === 'yandexSplit';

  const showSelect = useMemo(() => {
    if (!product.variants) return false;

    const { parameterGroupId } = product.variants;
    const place = product.parameterGroups.find((group) => group.id === parameterGroupId)?.place;

    return place === 'default';
  }, [product.variants, product.parameterGroups]);

  const separatedProductGroups = useMemo<ProductGroups>(() => {
    const groups: ProductGroups = { default: [], secondary: [] };

    product.parameterGroups.forEach((item) => {
      if (!groups[item.place]) groups[item.place] = [];

      if (item.id !== product.variants?.parameterGroupId) {
        groups[item.place].push(item);
      }
    });

    return groups;
  }, [product.parameterGroups, product.variants]);

  const handleBuy = useCallback(
    (e: MouseEvent) => {
      if (onClickProductLink) onClickProductLink(e);

      if (hasInCart) {
        openPage({ url: `${region.url}/order/check` });
        return;
      }

      ModalWindows.open('Cart', {
        products: [shopProduct],
        analyticsLabel,
      });
    },
    [onClickProductLink, hasInCart, shopProduct, analyticsLabel, openPage, region.url],
  );

  return (
    <div
      {...restProps}
      data-testid='product-card'
      itemScope={itemScope}
      className={cn(
        styles.card,
        {
          [styles.promo]: product.promo,
        },
        className,
      )}
    >
      <div className={styles.img}>
        <Link to={product.link} onClick={onClickProductLink}>
          <ProductImage
            className={cn(styles.image, {
              [styles.landscape]: orientation === 'landscape',
              [styles.portrait]: orientation === 'portrait',
            })}
            cnImage={styles.cnImage}
            src={firstImage?.src}
            alt={getProductName(product)}
            loading={imageLazyLoading ? 'lazy' : 'eager'}
            itemProp={itemScope && 'image'}
          />

          {isComingSoon && !needComingSoonInfo && (
            <OnSaleSoonTab showIcon className={styles.comingSoonTab} />
          )}

          {product.extraDiscount && (
            <LinearTag
              type='extra'
              className={cn(styles.extraSaleTag, {
                [styles.bottomPlacement]: isExtraSale,
              })}
            />
          )}
        </Link>

        <div className={styles.actions}>
          <ProductLike product={product} label='Листинг' />
        </div>
        {product.tags?.length > 0 && (
          <ProductTags
            className={cn(styles.tags, {
              [styles.hidden]: isExtraSale,
            })}
            tags={product.tags}
          />
        )}
      </div>
      <div className={styles.info}>
        <Link
          className={cn(
            styles.name,
            'ProductName',
            product.status === 'Disable' && 'ForCount',
            product.status === 'Active' && 'ActiveProduct',
          )}
          to={product.link}
          onClick={onClickProductLink}
        >
          <span itemProp={itemScope && 'name'}>{getProductName(product)}</span>
        </Link>

        {itemScope && <SEOMetaData product={product} />}
        <div
          className={cn(styles.wrapperPrice, {
            [styles.longPrice]: needBuyButton && isUZB,
            [styles.fullSpace]: isComingSoon,
          })}
        >
          <div className={cn(styles.price, { [styles.soon]: isComingSoon })}>
            {/* Требование SEO чтобы от сервера всегда приходила цена,
            и только на клиенте изменялась на Нет в наличии */}
            {isShowInfoTab && (
              <InfoTab
                showIcon={isComingSoon}
                unavailable={isNotAvailable}
                comingSoon={isComingSoon}
              />
            )}

            {!isNotAvailable && !isComingSoon && (
              <>
                <Price className={styles.actualPrice} price={product.price.actual} />
                {hasExpired && (
                  <Price
                    expired
                    className={styles.expiredPrice}
                    price={product.price.expired}
                    withoutCurrency={isUZB && !needBuyButton && !isMobileM}
                  />
                )}
                {hasDiscount && (
                  <Discount
                    className={styles.discount}
                    value={product.price.discount}
                    view='grayStroke'
                  />
                )}
              </>
            )}
          </div>

          {needBuyButton && !isComingSoon && !isNotAvailable && (
            <Button
              view='circle'
              theme={hasInCart ? 'transparent' : 'primary'}
              className={styles.button}
              onClick={handleBuy}
              data-testid={hasInCart ? 'icon-buy-button-added' : 'icon-buy-button'}
            >
              {hasInCart ? (
                <IconCheckBasket className={styles.iconBasket} />
              ) : (
                <IconBasket className={styles.iconBasket} theme='light' />
              )}
            </Button>
          )}
          {needBuyButton && isComingSoon && (
            <AboutReceiptButton isCircleView product={product} className={styles.button} />
          )}
        </div>
        {separatedProductGroups.default.length > 0 && (
          <Characteristics
            className={styles.parameters}
            groups={separatedProductGroups.default}
            product={product}
          />
        )}

        {showSelect && (
          <ProductSelect
            className={styles.sizes}
            product={product}
            onClickItem={onClickProductLink}
          />
        )}

        {/* По требованию SEO в некоторых местах сайта (например на странице категорий) необходимо получать в разметке информацию о характеристиках */}
        {hiddenCharacteristics && isSSR && (
          <>
            {separatedProductGroups.secondary?.length > 0 && (
              <Characteristics
                className={styles.hiddenCharacteristics}
                groups={separatedProductGroups.secondary}
                product={product}
              />
            )}
          </>
        )}

        {/* По требованию SEO в некоторых местах сайта (например на странице категорий) необходимо получать разметку кнопки купить */}
        {hiddenBuyLink && isSSR && <BuyButton href={product.link} />}
        {mainCredit && !hidenCreditTab && (
          <CreditTabWrapper
            mainCredit={mainCredit}
            className={cn(styles.creditTab, {
              [styles.yandexCreditTab]: isYandexSplit,
            })}
          />
        )}
      </div>
    </div>
  );
};

export default memo(CrossSaleProductCard);

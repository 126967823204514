import { GalleryWithProgress } from '@divlab/divanui';

import CardInView from '@Components/CardInView';
import DefaultBanner from './templates/DefaultBanner';
import styles from './SaleBanners.module.css';

import type { FC, HTMLAttributes, PropsWithChildren } from 'react';
import type { SaleBannerData } from '@Promo/typings';

export interface SaleBannersProps extends HTMLAttributes<HTMLDivElement> {
  banners: SaleBannerData[];
}

const Gallery: FC<PropsWithChildren> = ({ children }) => {
  return (
    <>
      <GalleryWithProgress
        className={styles.galleryContainer}
        cnGallery={styles.gallery}
        cnTrack={styles.track}
      >
        {children}
      </GalleryWithProgress>
      <div className={styles.list}>{children}</div>;
    </>
  );
};

const SaleBanners: FC<SaleBannersProps> = (props) => {
  const { banners, ...restProps } = props;

  return (
    <div {...restProps}>
      <Gallery>
        {banners.map((banner, index) => (
          <div key={banner.text.title}>
            <CardInView
              listTitle='banners'
              position={index}
              card={banner}
              cardType='promo'
              id={banner.text?.title}
              targetPathname={banner.link}
            >
              <DefaultBanner className={styles.banner} banner={banner} />
            </CardInView>
          </div>
        ))}
      </Gallery>
    </div>
  );
};

export default SaleBanners;

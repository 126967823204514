import { cn } from '@divlab/divanui';
import { IconAlert } from '@divlab/divanui/icons';

import useTranslation from '@Queries/useTranslation';
import styles from './InfoTab.module.css';

import type { FC, HTMLAttributes } from 'react';

interface InfoTabProps extends HTMLAttributes<HTMLDivElement> {
  unavailable?: boolean;
  comingSoon?: boolean;
  showIcon?: boolean;
}

const InfoTab: FC<InfoTabProps> = (props) => {
  const { unavailable, comingSoon, showIcon, className } = props;
  const { t } = useTranslation();

  return (
    <div className={cn(styles.main, className)}>
      {showIcon && <IconAlert className={styles.icon} />}
      <span
        className={cn(styles.text, {
          [styles.saleSoon]: comingSoon,
          [styles.zeroOffset]: !showIcon,
        })}
      >
        {unavailable && !comingSoon && t('ui.unavailable')}
        {comingSoon && t('ui.coming-soon-info')}
      </span>
    </div>
  );
};

export default InfoTab;

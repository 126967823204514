import { memo } from 'react';

import type { FC } from 'react';
import type { CatalogData } from '@Types/Catalog';

export interface SEOAggregateRatingProps {
  seo: CatalogData['seo'];
}

const SEOAggregateRating: FC<SEOAggregateRatingProps> = (props) => {
  const { seo } = props;

  return (
    <div itemProp='aggregateRating' itemScope itemType='http://schema.org/AggregateRating'>
      <meta itemProp='ratingValue' content='5' />
      <meta itemProp='reviewCount' content={seo.reviewCount.toString()} />
      <meta itemProp='bestRating' content='5' />
      <meta itemProp='worstRating' content='1' />
    </div>
  );
};

export default memo(SEOAggregateRating);

import close from './close';
import changeVisible from './changeVisible';
import add from './add';

const promoPopups = {
  close,
  changeVisible,
  add,
};

export * from './store';
export default promoPopups;

import { createDerived, createStore } from '@kundinos/nanostores';
import { useStore } from '@kundinos/nanostores/react';

import type { PopupStore } from './type';

export const popupStore = createStore<PopupStore>(() => {
  popupStore.set({ items: [] });
});

export const currentPopupStore = createDerived(popupStore, ({ items }) => {
  return items[items.length - 1];
});

export const useCurrentPopup = () => {
  return useStore(currentPopupStore);
};

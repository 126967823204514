import { memo, useMemo } from 'react';

import * as Meta from '@Queries/Meta';
import MainSelect from '@UI/MainSelect';
import SizesSelect from '@UI/SizesSelect';

import type { ProductData } from '@Types/Product';
import type { SizesSelectProps } from '@UI/SizesSelect';
import type { FC } from 'react';

export interface ProductSelectProps extends SizesSelectProps {
  product: ProductData;
}

const ProductSelect: FC<ProductSelectProps> = (props) => {
  const { product, isGroupedByName, ...restProps } = props;
  const meta = Meta.useMeta();
  const currency = meta.data?.currency;

  const sizeItems = useMemo(() => {
    return product.variants.values.map((variant, index: number) => {
      const sizes = [];
      const value = product.parameterValues.find((val) => val.parameterId === variant.items[0].id);
      const unit = product.units.find((u) => value && u.id === value.unitId);
      const price = variant.price && {
        value: variant.price,
        currency,
      };

      variant.items?.forEach((item) => {
        const parameter = product.parameters.find((p) => p.id === item.id);

        if (parameter) {
          sizes.push({ title: parameter.title, value: Math.round(+item.value) });
        }
      });

      return {
        sizes,
        id: variant.id.toString(),
        title: variant.parameters,
        name: variant.parameters,
        unit: unit?.title,
        href: variant.link,
        price,
        selected: index === 0,
        isActive: product.isActive,
      };
    });
  }, [currency, product]);

  const theme = useMemo(() => {
    const currentGroup = product.parameterGroups.find(
      (group) => group.id === product.variants.parameterGroupId,
    );

    if (currentGroup && !!currentGroup.theme) return currentGroup.theme;
  }, [product.variants, product.parameterGroups]);

  return theme === 'sizes' ? (
    <SizesSelect
      {...restProps}
      wide
      titleField={<span className='Specifications'>{product.variants.title}</span>}
      items={sizeItems}
      isGroupedByName={isGroupedByName}
    />
  ) : (
    <MainSelect {...restProps} title={product.variants.title} items={sizeItems} />
  );
};

export default memo(ProductSelect);

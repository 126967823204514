import { lazy } from 'react';

import PopupManager from '@Promo/elems/PopupManager';
import Popups from '@Promo/elems/Popups';

import type { ReactElement } from 'react';

export interface Params {
  slug: string;
}

const PopupBannerTemplate = lazy(() => import('@Promo/templates/PopupBannerTemplate'));

const usePromoPopup = (params: Params): ReactElement => {
  const { slug } = params;

  return (
    <Popups
      slug={slug}
      render={(popup) => {
        if (!popup) return null;

        const template = popup.template || 'default';

        return (
          <PopupManager popup={popup}>
            {template === 'default' && <PopupBannerTemplate popup={popup} />}
          </PopupManager>
        );
      }}
    />
  );
};

export default usePromoPopup;

import { cloneElement, memo, useCallback, useEffect, useState, useRef } from 'react';
import { cn } from '@divlab/divanui';
import { CSSTransition } from 'react-transition-group';

import promoPopups, { useCurrentPopup } from '../../Stores/Popups';
import styles from './PopupManager.module.css';

import type { PopupData } from '@Promo/typings';
import type { FC, HTMLAttributes, ReactElement } from 'react';

export interface PopupManagerProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  popup: PopupData;
  children: ReactElement;
}

const PopupManager: FC<PopupManagerProps> = (props) => {
  const { className, popup, children, ...restProps } = props;
  const [canDisplayWithDelay, setCanDisplayWithDelay] = useState(true);
  const [scrolled, setScrolled] = useState(false);
  const timerId = useRef(null);
  const currentPopup = useCurrentPopup();

  const handleClosePopup = useCallback(() => {
    if (!currentPopup) return;

    promoPopups.close(currentPopup.id);
  }, [currentPopup]);

  const handleScroll = useCallback(() => {
    if (window.scrollY > currentPopup.data.options.scroll) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  }, [currentPopup]);

  // Условия для отображения попапа с типом delay
  useEffect(() => {
    if (!currentPopup || currentPopup.data.type !== 'delay') return;

    setTimeout(() => {
      promoPopups.changeVisible(currentPopup.id, true);
    }, currentPopup.data.options.delay);
  }, [currentPopup]);

  // Условия для отображения попапа с типом slide-up
  useEffect(() => {
    const getPopupWithScroll = () => {
      handleScroll();
      promoPopups.changeVisible(currentPopup.id, scrolled);
    };

    if (!currentPopup || currentPopup.data.type !== 'slide-up') return;

    window.addEventListener('scroll', getPopupWithScroll);

    return () => window.removeEventListener('scroll', getPopupWithScroll);
  }, [currentPopup, scrolled, handleScroll]);

  // Условия для отображения попапа с типом slide-up-delay
  useEffect(() => {
    const getPopupWithScrollAndDelay = () => {
      handleScroll();

      if (canDisplayWithDelay && scrolled) {
        timerId.current = setTimeout(() => {
          promoPopups.changeVisible(currentPopup.id, true);
        }, currentPopup.data.options?.delay);
        setCanDisplayWithDelay(false);
      } else if (!scrolled && !canDisplayWithDelay) {
        promoPopups.changeVisible(currentPopup.id, false);
        clearTimeout(timerId.current);
        setCanDisplayWithDelay(true);
      }
    };

    if (!currentPopup || currentPopup.data.type !== 'slide-up-delay') return;

    window.addEventListener('scroll', getPopupWithScrollAndDelay);

    return () => window.removeEventListener('scroll', getPopupWithScrollAndDelay);
  }, [currentPopup, canDisplayWithDelay, scrolled, handleScroll]);

  useEffect(() => {
    promoPopups.add(popup);
  }, [popup]);

  return (
    <CSSTransition
      classNames={{
        enter: styles.enter,
        enterActive: styles.enterActive,
        enterDone: styles.enterDone,
        exit: styles.exit,
        exitActive: styles.exitActive,
        exitDone: styles.exitDone,
      }}
      in={currentPopup?.visible}
      timeout={500}
      unmountOnExit
    >
      {cloneElement(children, {
        ...restProps,
        ...children.props,
        className: cn(
          styles.popup,
          {
            [styles.typeSlideUp]: currentPopup?.data.type === 'slide-up' || 'slide-up-delay',
            [styles.typeDelay]: currentPopup?.data.type === 'delay',
          },
          className,
        ),
        onClose: handleClosePopup,
      })}
    </CSSTransition>
  );
};

export default memo(PopupManager);

import { update } from '@kundinos/nanostores';

import getCookie from '@Utils/getCookie';
import { popupStore } from './store';

function shouldShow(popup, visible) {
  if (!popup.data.cookie) {
    return !popup.closed && visible;
  }
  // Не показываем попап, если у пользователя есть кука
  return popup.data.cookie && !getCookie(popup.data.cookie.key) && !popup.closed && visible;
}

function changeVisible(id: string, visible = true) {
  update(popupStore, (value) => {
    const newItems = value.items.map((item) => ({
      ...item,
      visible: item.id === id ? shouldShow(item, visible) : item.visible,
    }));
    return { ...value, items: newItems };
  });
}

export default changeVisible;

import { cn, Discount, useMedias } from '@divlab/divanui';

import Img from '@UI/Img';
import Link from '@Navigation/Link';
import Text from '@Promo/elems/Text';
import styles from './DefaultBanner.module.css';

import type { FC, HTMLAttributes } from 'react';
import type { SaleBannerData } from '@Promo/typings';

export interface DefaultBannersProps extends HTMLAttributes<HTMLDivElement> {
  banner: SaleBannerData;
}

const DefaultBanner: FC<DefaultBannersProps> = (props) => {
  const { className, banner, ...restProps } = props;
  const { isDesktop } = useMedias();
  const text = banner.text;
  const image =
    banner.resources.default[isDesktop ? 'mobileImage' : 'desktopImage'] ||
    banner.resources.default.desktopImage;

  return (
    <div className={cn(styles.wrapper, className)} {...restProps}>
      <Link className={styles.container} to={banner.link}>
        <div className={styles.imageContainer}>
          <Img className={styles.image} src={image} />

          {text?.sticker && (
            <Discount
              className={styles.discount}
              value={text.sticker}
              view='white'
              simple={banner.sticker.type !== 'sale'}
              customText
            />
          )}
        </div>

        {text?.title && (
          <Text
            className={styles.title}
            desktop={text.title}
            mobile={text.titleMobile}
            isMobile={isDesktop}
          />
        )}

        {text?.subtitle && (
          <Text
            className={styles.subtitle}
            desktop={text.subtitle}
            mobile={text.titleMobile}
            isMobile={isDesktop}
          />
        )}
      </Link>
    </div>
  );
};

export default DefaultBanner;

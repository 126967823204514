import { update } from '@kundinos/nanostores';

import setCookie from '@Utils/setCookie';
import { popupStore } from './store';

function close(id: string) {
  update(popupStore, (value) => {
    const newItems = value.items.map((item) => {
      const shouldClose = item.id === id;
      return {
        ...item,
        visible: shouldClose ? false : item.visible,
        closed: shouldClose ? true : item.closed,
      };
    });

    return { ...value, items: newItems };
  });

  const currentItem = popupStore.value.items.find((item) => item.id === id);
  if (currentItem.data.cookieKey) {
    setCookie(currentItem.data.cookieKey, '1', { expires: currentItem.data.endDate });
  }
}

export default close;

import { memo } from 'react';

import * as Meta from '@Queries/Meta';

import type { FC } from 'react';
import type { CatalogData } from '@Types/Catalog';

export interface SEOAggregateOfferProps {
  seo: CatalogData['seo'];
}

const SEOAggregateOffer: FC<SEOAggregateOfferProps> = (props) => {
  const { seo } = props;
  const meta = Meta.useMeta();

  return (
    <div itemProp='offers' itemScope itemType='http://schema.org/AggregateOffer'>
      <meta itemProp='lowPrice' content={seo.lowPrice.toString()} />
      <meta itemProp='highPrice' content={seo.highPrice.toString()} />
      <meta itemProp='priceCurrency' content={meta.data?.currency} />
      <link itemProp='availability' href='http://schema.org/InStock' />
    </div>
  );
};

export default memo(SEOAggregateOffer);
